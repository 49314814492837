import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

function Proposal({ data, onDelete, onRemoveMember }) {
    const handleRemoveProposalMember = (e) => {
        e.stopPropagation(); // Prevent the event from bubbling up to the parent element
        if (onRemoveMember) {
            onRemoveMember(data.id);
        }
    };

    return (
        <li key={data.id} className='proposal__list'>
            description: {data.description}
            <br />
            details: {data.details}
            <button type="button" className="remove-button" onClick={handleRemoveProposalMember}>
                <FontAwesomeIcon icon={faTrash} />
            </button>
        </li>
    );
}

export default Proposal;
