import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { db, auth } from '../../Firebase/Firebase';

// Helper function to format restaurant data
const formatRestaurantData = (data) => {
    const {
        name = "",
        rating = "",
        image_url = "",
        phone = "",
        display_phone = "",
        review_count = "",
        categories = [],
        is_closed = "",
        price = "",
        hours = [],
        transactions = [],
    } = JSON.parse(data);

    return {
        name,
        rating,
        image_url,
        phone,
        display_phone,
        review_count,
        categories,
        is_closed,
        price,
        hours,
        transactions,
    };
};

const Restaurants = () => {
    const [userId, setUserId] = useState(null);
    const [breakfastRestaurants, setBreakfastRestaurants] = useState([]);
    const [lunchRestaurants, setLunchRestaurants] = useState([]);
    const [dinnerRestaurants, setDinnerRestaurants] = useState([]);

    useEffect(() => {
        // Listen for authentication changes and set the userId
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log("User logged in:", user.uid);
                setUserId(user.uid);
            } else {
                console.log("No user logged in.");
                setUserId(null);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        // Fetch restaurants data when userId is available
        const fetchRestaurants = async () => {
            if (!userId) {
                console.error("userId is undefined or invalid");
                return;
            }

            try {
                console.log("userId:", userId); // Log userId for debugging
                const userDocRef = doc(db, 'userspublic', userId);
                const userDocSnap = await getDoc(userDocRef);

                if (userDocSnap.exists()) {
                    console.log("userDocSnap data:", userDocSnap.data()); // Log user data for debugging
                    const userData = userDocSnap.data();
                    const removedForMeal1 = userData['removedformeal-1'] || [];
                    const removedForMeal2 = userData['removedformeal-2'] || [];
                    const removedForMeal3 = userData['removedformeal-3'] || [];

                    // Function to fetch restaurant data based on restaurant IDs
                    const fetchRestaurantData = async (ids) => {
                        // Filter out invalid IDs
                        const validIds = ids.filter(id => typeof id === 'string' && id.trim() !== '');
                        if (validIds.length === 0) return [];

                        const restaurantPromises = validIds.map(id => getDoc(doc(db, "restaurants", id)));
                        const restaurantDocs = await Promise.all(restaurantPromises);
                        const restaurants = restaurantDocs
                            .map(doc => doc.exists() ? formatRestaurantData(doc.data().restaurantdata) : null)
                            .filter(data => data !== null);

                        // Console log to see what is being retrieved
                        console.log("Fetched Restaurants Data:", restaurants);

                        return restaurants;
                    };

                    // Fetch breakfast restaurants
                    if (removedForMeal1.length > 0) {
                        const breakfastData = await fetchRestaurantData(removedForMeal1);
                        setBreakfastRestaurants(breakfastData);
                    }

                    // Fetch lunch restaurants
                    if (removedForMeal2.length > 0) {
                        const lunchData = await fetchRestaurantData(removedForMeal2);
                        setLunchRestaurants(lunchData);
                    }

                    // Fetch dinner restaurants
                    if (removedForMeal3.length > 0) {
                        const dinnerData = await fetchRestaurantData(removedForMeal3);
                        setDinnerRestaurants(dinnerData);
                    }
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching restaurants:", error);
            }
        };

        fetchRestaurants();
    }, [userId]);

    return (
        <div style={{ color: 'white' }}>
            <h2>Breakfast</h2>
            {breakfastRestaurants.length > 0 ? (
                breakfastRestaurants.map((restaurant, index) => (
                    <div key={index}>
                        <p>Name: {restaurant.name}</p>
                        <p>Rating: {restaurant.rating}</p>
                    </div>
                ))
            ) : (
                <p>No breakfast restaurants found</p>
            )}

            <h2>Lunch</h2>
            {lunchRestaurants.length > 0 ? (
                lunchRestaurants.map((restaurant, index) => (
                    <div key={index}>
                        <p>Name: {restaurant.name}</p>
                        <p>Rating: {restaurant.rating}</p>
                    </div>
                ))
            ) : (
                <p>No lunch restaurants found</p>
            )}

            <h2>Dinner</h2>
            {dinnerRestaurants.length > 0 ? (
                dinnerRestaurants.map((restaurant, index) => (
                    <div key={index}>
                        <p>Name: {restaurant.name}</p>
                        <p>Rating: {restaurant.rating}</p>
                    </div>
                ))
            ) : (
                <p>No dinner restaurants found</p>
            )}
        </div>
    );
};

export default Restaurants;
