import React, { useEffect, useState, useRef } from 'react';
import './Navbar.css';
import { Link, useNavigate } from "react-router-dom";
import { db, auth } from '../../Firebase/Firebase';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { usePopper } from 'react-popper';

const Navbar = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [userPublicDoc, setUserPublicDoc] = useState(null);
    const [userProfileName, setUserProfileName] = useState(null);
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef(null);
    const cogRef = useRef(null);
    const containerRef = useRef(null);
    const navigate = useNavigate();

    const { styles, attributes } = usePopper(cogRef.current, menuRef.current, {
        placement: 'bottom-end',
    });

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user);
            } else {
                setCurrentUser(null);
            }
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (!currentUser) return;
        const unsub = onSnapshot(doc(db, "userspublic", currentUser.uid), (doc) => {
            setUserPublicDoc(doc.data());
        });
        return () => unsub();
    }, [currentUser]);

    useEffect(() => {
        if (!currentUser) return;
        const fetchUserProfileName = async () => {
            const userDocRef = doc(db, 'users', currentUser.uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                setUserProfileName(userDoc.data().name);
            } else {
                setUserProfileName(null);
            }
        };
        fetchUserProfileName();
    }, [currentUser]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target) &&
                cogRef.current && !cogRef.current.contains(event.target) &&
                containerRef.current && !containerRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };

        window.addEventListener('mousedown', handleClickOutside);
        return () => {
            window.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMenu]);

    const toggleMenu = () => {
        setShowMenu(prevShowMenu => !prevShowMenu);
    };

    const openProfile = () => {
        let myElement = document.getElementById("profileWindow");
        if (myElement != null) {
            myElement.style.display = 'block';
        } else {
            document.getElementById("navBarProfileLink").click();
        }
        return true;
    };

    const handleCreateNewProposalClick = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    navigate("/newproposal");
                },
                (error) => {
                    navigate("/newproposal");
                }
            );
        } else {
            navigate("/newproposal");
        }
    };

    return (
        <nav>
            <ul>
                <div className="main__navbar" ref={containerRef}>
                    <div className="main__navbar-links">
                        {userPublicDoc && userPublicDoc.avatarurl ? (
                            <div className="user-info">
                                <img src={userPublicDoc.avatarurl} alt="User Avatar" className="user-avatar" />
                                <div className="user-info-text">
                                    <div className="user-displayname">{userPublicDoc.displayname}</div>
                                    {userProfileName && <div className="user-profile-name">{userProfileName}</div>}
                                </div>
                            </div>
                        ) : (
                            <p>Loading user data...</p>
                        )}
                        <div className="main__navbar-links_container">
                            <li><p><Link to="/proposals">Home</Link></p></li>
                            <div className="link_to_new_proposal">
                                <li><p><span onClick={handleCreateNewProposalClick}>Create new proposal</span></p></li>
                            </div>
                        </div>
                    </div>
                    <div className="main__navbar-sign">
                        <FontAwesomeIcon icon={faCog} className="settings-icon" onClick={toggleMenu} ref={cogRef} />
                        {showMenu && (
                            <div ref={menuRef} style={styles.popper} {...attributes.popper} className="popup-menu">
                                <ul>
                                    <li onClick={openProfile}>Edit Profile<Link to="/profile" id="navBarProfileLink"></Link></li>
                                    <li><Link to="/settings">Account Settings</Link></li>
                                    <li><Link to="/Friends">Friends...</Link></li>
                                    <li><Link to="/Restaurants">Restaurants</Link></li>
                                    <li><a href="https://twentyies.com/apps" target="_blank" rel="noopener noreferrer">About Twentyies Inc...</a></li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </ul>
        </nav>
    );
}

export default Navbar;
