import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth, removeProposalMember } from '../../Firebase/Firebase';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';
import Proposal from './Proposal';
import './ProposalList.css';
import { Outlet } from 'react-router-dom';

function ProposalList(props) {
    const currentUser = auth.currentUser;
    const [document, setDocument] = useState(null);
    const [proposalList, setProposalList] = useState([]);
    const [oldProposals, setOldProposals] = useState([]);
    const selectedProposalId = props.proposalId;
    const navigate = useNavigate();

    function handleClick(proposalId) {
        navigate(`/proposals/${proposalId}`);
    }

    useEffect(() => {
        if (!currentUser) {
            console.log("No current user is logged in.");
            return;
        }
        console.log(`Listening to user document for user: ${currentUser.uid}`);
        const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
            if (doc.exists()) {
                console.log("User document data:", doc.data());
                setDocument(doc.data());
            } else {
                console.log("No document found for the current user.");
            }
        }, (error) => {
            console.error("Error fetching user document:", error.message);
        });
        return () => unsub();
    }, [currentUser]);

    useEffect(() => {
        if (!document) {
            console.log("No user document available to fetch proposals.");
            return;
        }

        const newArrayList = [];
        const oldArrayList = [];

        console.log("Fetching joined proposals...");

        if (!document.joinedproposals || !Array.isArray(document.joinedproposals)) {
            console.log("No joined proposals found or joinedproposals is not an array.");
            return;
        }

        const refs = document.joinedproposals.map(id => doc(db, "proposals", id));
        refs.forEach(ref => {
            getDoc(ref).then((snapshot) => {
                if (snapshot.exists()) {
                    const proposalData = snapshot.data();
                    const proposalCreatedTime = new Date(proposalData.createdtime);

                    const oneWeekAgo = new Date();
                    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

                    if (proposalCreatedTime <= oneWeekAgo) {
                        oldArrayList.push(proposalData);
                    } else {
                        newArrayList.push(proposalData);
                    }

                    console.log(`Added proposal ID: ${proposalData.id} to the appropriate list.`);

                    setProposalList([...newArrayList].sort((a, b) =>
                        new Date(a.eventtime) < new Date(b.eventtime) ? 1 : -1
                    ));
                    setOldProposals([...oldArrayList].sort((a, b) =>
                        new Date(a.eventtime) < new Date(b.eventtime) ? 1 : -1
                    ));
                } else {
                    console.log(`Proposal document with ID ${ref.id} does not exist.`);
                }
            }).catch((error) => {
                console.error(`Error getting proposal document with ID ${ref.id}:`, error.message);
            });
        });
    }, [document]);

    const handleRemoveProposalMember = (proposalId) => {
        console.log(`Removing member from proposal with ID: ${proposalId}`);
        removeProposalMember(proposalId, currentUser.uid, (status) => {
            console.log(status);
            handleDelete(proposalId);
        });
    };

    const handleDelete = (id) => {
        console.log(`Deleting proposal with ID: ${id}`);
        setProposalList(proposalList.filter(proposal => proposal.id !== id));
        setOldProposals(oldProposals.filter(proposal => proposal.id !== id));
    };

    const deleteAllOldEvents = () => {
        console.log("Deleting all old proposals...");
        oldProposals.forEach(proposal => {
            console.log(`Deleting old proposal ID: ${proposal.id}`);
            handleRemoveProposalMember(proposal.id);
        });
    };

    return (
        <>
            {!document ? (
                <div>Loading...</div>
            ) : (
                <>
                    <nav>
                        <ul>
                            <div className="proposal-list-container">
                                <div className="proposal-list">
                                    {proposalList.length > 0 ? (
                                        proposalList.map((proposalData) => (
                                            <li key={proposalData.id}>
                                                <button type="button" onClick={() => handleClick(proposalData.id)}>
                                                    {proposalData.id !== selectedProposalId ? (
                                                        <div className='proposal__list'>
                                                            <Proposal data={proposalData} onDelete={handleDelete} onRemoveMember={handleRemoveProposalMember} />
                                                        </div>
                                                    ) : (
                                                        <div className='proposal__listselected'>
                                                            <Proposal data={proposalData} onDelete={handleDelete} onRemoveMember={handleRemoveProposalMember} />
                                                        </div>
                                                    )}
                                                </button>
                                            </li>
                                        ))
                                    ) : (
                                        <p>No proposals found.</p>
                                    )}
                                </div>
                            </div>
                        </ul>
                    </nav>
                    <button onClick={deleteAllOldEvents}>Delete Old Proposals</button>
                    <Outlet />
                </>
            )}
        </>
    );
}

export default ProposalList;
