import React, { useEffect, useState, } from "react";
import GoogleMapReact from 'google-map-react';
import { render } from "react-dom";
import  './GoogleMap.css';


const GOOGLE_API_KEY = "AIzaSyDbKWSX-WO1oQyFbmlc9f5Z5YlxMgEtVCo";

const Marker = ({ text }) => (
  <div style={{ textAlign: 'center', transform: 'translate(-50%, -100%)' }}>
    <div style={{
      width: '24px',
      height: '24px',
      backgroundColor: 'red',
      borderRadius: '50%',
      position: 'relative'
    }}>
      <div style={{
        position: 'absolute',
        width: '0',
        height: '0',
        borderLeft: '12px solid transparent',
        borderRight: '12px solid transparent',
        borderTop: '24px solid red',
        top: '24px',
        left: '0'
      }} />
    </div>
    
  </div>
);



export default function GoogleMap(props) {
  const [isCircle, setCircle] = useState(false);

  useEffect(() => {
    // Update isCircle after the initial render
    setCircle(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this effect runs only once after initial render

  const CircleComponent = ({ size, text }) => {
    const circleStyle = {
      position: "aboslute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      width: `${size}px`,
      height: `${size}px`,
      backgroundColor: "DodgerBlue",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      opacity: 0.5, // Set opacity to 50%
    };
    return (
      <div style={circleStyle}>
        <p className="text">{text}</p>
      </div>
    );
  };

  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  let defaultProps = {};
  let restaurantMarker = {};

  if (!props.restaurant) {
    defaultProps = {
      center: {
        lat: props.proposal.location.latitude,
        lng: props.proposal.location.longitude
      },
      zoom: 11
    };
  } else {
    console.log("Current lat:", props.restaurant.coordinates.latitude);

    defaultProps = {
      center: {
        lat: props.restaurant.coordinates.latitude,
        lng: props.restaurant.coordinates.longitude
      },
      zoom: 11
    };

    restaurantMarker = {
      text: props.restaurant.name
    };

    console.log("NAME OF RESTAURANT:", props.restaurant.name);
  }
  const circleSize = props.proposal.range/30; // Limit the size to 200px or any appropriate max value

  return (
    <>
      <div>
        {console.log("GOOGLE MAP Current data", props.proposal)}
      </div>
      <ul className="map__container">
        <div style={{ height: "50%", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: [GOOGLE_API_KEY] }}
            resetBoundsOnResize={true}
            center={defaultProps.center}
            zoom={defaultProps.zoom}
            options={{
              gestureHandling: 'none',
              zoomControl: false,
              disableDefaultUI: true,
              draggable: false,
              clickableIcons: false,
              scrollwheel: false,
              disableDoubleClickZoom: true
            }}
          >
            {console.log("Current GoogleMap location", defaultProps.center)}
            {restaurantMarker.text ? (
                <Marker
                lat={defaultProps.center.lat}
                lng={defaultProps.center.lng}
                text={restaurantMarker.text}
              />
            
            ) : (
              isCircle && (
                <CircleComponent
                  size= { circleSize}
                />
              )
            )}
            {console.log("NAME OF RESTAURANT NOW!:", restaurantMarker.text)}
          </GoogleMapReact>
        </div>
      </ul>
    </>
  );
}
