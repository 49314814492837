import React, { useEffect, useState, useCallback } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { db, setProposalSorted, setProposalState, makeDecision } from '../../Firebase/Firebase';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import './VotingSort.css';

const ItemTypes = {
  RESTAURANT: 'restaurant',
};

const RestaurantItem = ({ restaurant, index, moveRestaurant }) => {
  const ref = React.useRef(null);
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.RESTAURANT,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.RESTAURANT,
    hover: (item) => {
      if (item.index !== index) {
        moveRestaurant(item.index, index);
        item.index = index;
      }
    },
  });

  drag(drop(ref));

  return (
    <li
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
        userSelect: 'none',
        padding: '16px',
        margin: '0 0 8px 0',
        minHeight: '50px',
        backgroundColor: '#fff',
        color: '#333',
        border: '1px solid #ccc',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <span style={{
        position: 'absolute',
        left: '10px',
        fontSize: '16px',
        fontWeight: 'bold',
        backgroundColor: '#fff',
        paddingRight: '8px',
      }}>
        {index + 1}
      </span>
      {restaurant.image_url && (
        <img
          src={restaurant.image_url}
          alt={restaurant.name}
          style={{
            width: '50px',
            height: '50px',
            marginLeft: '60px',
            borderRadius: '4px',
          }}
        />
      )}
      <span style={{ marginLeft: '16px' }}>{restaurant.name}</span>
    </li>
  );
};

const VotingSort = ({ proposalId }) => {
  const [restaurantPool, setRestaurantPool] = useState([]);
  const [restaurantData, setRestaurantData] = useState({});
  const [proposalState, setProposalStateLocal] = useState('');
  const [isSortingDone, setIsSortingDone] = useState(false);
  const [decisionMade, setDecisionMade] = useState(false);
  const userId = getAuth().currentUser?.uid;

  useEffect(() => {
    if (typeof proposalId !== 'string') {
      console.error("Invalid proposalId:", proposalId);
      return;
    }

    const unsub = onSnapshot(doc(db, "proposals", proposalId), async (doc) => {
      if (doc.exists()) {
        const pool = doc.data().restaurantpool || [];
        setRestaurantPool(pool);
        setProposalStateLocal(doc.data().state);
        await fetchRestaurantData(pool);
      } else {
        console.log("No such document!");
      }
    });

    return () => unsub();
  }, [proposalId]);

  const fetchRestaurantData = async (pool) => {
    const data = {};
    const promises = pool.map(async (restaurantId) => {
      if (typeof restaurantId !== 'string') {
        console.error("Invalid restaurantId:", restaurantId);
        return;
      }

      const docRef = doc(db, "restaurants", restaurantId);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          try {
            const parsedData = JSON.parse(docSnap.data().restaurantdata);
            data[restaurantId] = parsedData;
          } catch (error) {
            console.error(`Error parsing restaurant data for ID ${restaurantId}:`, error);
          }
        } else {
          console.log("No such restaurant document!");
        }
      } catch (error) {
        console.error(`Error getting restaurant document for ID ${restaurantId}:`, error);
      }
    });

    await Promise.all(promises);
    setRestaurantData(data);
  };

  const moveRestaurant = useCallback((dragIndex, hoverIndex) => {
    const updatedPool = [...restaurantPool];
    const [draggedItem] = updatedPool.splice(dragIndex, 1);
    updatedPool.splice(hoverIndex, 0, draggedItem);
    setRestaurantPool(updatedPool);
  }, [restaurantPool]);

  const handleDoneSorting = async () => {
    const totalCompares = restaurantPool.length;

    const proposalData = {
      id: proposalId,
      sorted: restaurantPool,
      totalcompares: totalCompares,
    };

    try {
      await setProposalSorted(proposalData);
      console.log("Sorted restaurants updated successfully");
      setDecisionMade(true);
      setIsSortingDone(true);

      const proposalDoc = await getDoc(doc(db, "proposals", proposalId));
      if (!proposalDoc.exists()) {
        console.log("No such document!");
        return;
      }

      const proposalState = proposalDoc.data().state;

      if (proposalState === "sorting") {
        const decisionResult = await makeDecision({ proposalId });
        console.log("DECISION RESULT", decisionResult);

        if (decisionResult && decisionResult.length > 0) {
          const decidedProposalData = {
            state: "decided",
            id: proposalId,
            decision: decisionResult[0],
          };
          console.log("DECISION", decidedProposalData.decision);

          await setProposalState(decidedProposalData);
          console.log("Proposal state set to decided successfully");
        } else {
          console.error("Error: decisionResult is not valid");
        }
      }
    } catch (error) {
      console.error("Error in handleDoneSorting:", error);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      {!isSortingDone && !decisionMade && proposalState !== 'decided' && (
        <div className="voting-sort" style={{ display: 'flex' }}>
          <div style={{ flexGrow: 1 }}>
            <h2>Sort Restaurants</h2>
            {restaurantPool.length > 0 && (
              <>
                <ul
                  style={{
                    padding: 0,
                    margin: 0,
                    listStyle: 'none',
                    minHeight: '100px',
                  }}
                >
                  {restaurantPool.map((restaurantId, index) => (
                    <RestaurantItem
                      key={restaurantId}
                      index={index}
                      restaurant={restaurantData[restaurantId] || {}}
                      moveRestaurant={moveRestaurant}
                    />
                  ))}
                </ul>
                <button
                  onClick={handleDoneSorting}
                  style={{
                    marginTop: '16px',
                    padding: '8px 16px',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  Done Sorting
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </DndProvider>
  );
};

export default VotingSort;
