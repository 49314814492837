import React from 'react';

function Friend({ data, onClick }) {
    const friendName = data.displayname;

    return (
        <li key={data.id} onClick={onClick}>
            name: {friendName}
        </li>
    );
}

export default Friend;
