import React, { useEffect, useState, useRef } from "react";
import { auth, getRestaurant, inviteFriend, startVoting, setProposalPublic, finishTutorial } from '../../Firebase/Firebase';
import GoogleMap from "../../components/GoogleMap/GoogleMap";
import PeopleList from "../../components/PeopleList/PeopleList";
import './ContentArea.css';
import { useParams } from "react-router-dom";
import { db } from "../../Firebase/Firebase";
import { doc, onSnapshot } from "firebase/firestore";
import FriendList from "../../components/FriendList/FriendList";
import VotingSort from "../../components/VotingSort/VotingSort";

function ContentArea(props) {
    const [visibleItem, setVisibleItem] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [document, setDocument] = useState(null);
    const [friendsList, setFriendsList] = useState([]);
    const [userName, setUserName] = useState(null);
    const [restaurantData, setRestaurantData] = useState(null);
    const [inviteDropdownVisible, setInviteDropdownVisible] = useState(false);
    const [proposalState, setProposalState] = useState('');
    const [newUserExperience, setNewUserExperience] = useState(false);
    const [showTutorial, setShowTutorial] = useState(false);
    const inviteDataRef = useRef(null);

    const styles = {
        border: '2px solid rgba(255, 255, 255, 1.0)',
    };

    useEffect(() => {
        const unsub = onSnapshot(doc(db, "proposals", props.proposalId), (doc) => {
            setDocument(doc);
            setProposalState(doc.data().state);
            setVisibleItem(false);
        });
        return () => unsub();
    }, [props.proposalId]);

    // Debugging logs added to finishTutorial functionality
    useEffect(() => {
        if (auth.currentUser) {
            const userDocRef = doc(db, "users", auth.currentUser.uid);
            const unsub = onSnapshot(userDocRef, (doc) => {
                if (doc.exists()) {
                    const newUserExp = doc.data().newuserexperience;
                    setNewUserExperience(newUserExp);
                    console.log('New user experience:', newUserExp);
                    if (newUserExp === "false") {
                        setShowTutorial(true);
                        console.log('Calling finishTutorial function...');
                        finishTutorial("true")
                            .then((response) => {
                                console.log('Tutorial finished successfully:', response);
                            })
                            .catch((error) => {
                                console.error('Error finishing tutorial:', error.message);
                            });
                    }
                }
            });
            return () => unsub();
        }
    }, []);

    const fetchFriendList = (ownerId) => {
        const unsub = onSnapshot(doc(db, "users", ownerId), (doc) => {
            if (doc.exists()) {
                setFriendsList(doc.data().friends || []);
                setUserName(doc.data().name);
            } else {
                setFriendsList([]);
            }
        });
        return unsub;
    };

    useEffect(() => {
        if (document) {
            const ownerId = document.data().owner;
            if (ownerId) {
                const unsub = fetchFriendList(ownerId);
                return () => unsub();
            }
        }
    }, [document]);

    useEffect(() => {
        if (document && document.data().decision) {
            getRestaurant(document.data().decision, (response) => {
                setRestaurantData(response);
            });
            const unsub = onSnapshot(doc(db, "restaurants", document.data().decision), (doc) => {
                const docParsedData = JSON.parse(doc.data().restaurantdata);
                setRestaurantData(docParsedData);
            });
            return () => unsub();
        } else {
            setRestaurantData(null);
        }
    }, [document]);

    useEffect(() => {
        if (restaurantData) {
            setImageSrc(restaurantData.image_url);
        } else {
            setImageSrc(null);
        }
    }, [restaurantData]);

    const handleInvite = () => {
        if (document && document.data().description && document.data().id) {
            inviteDataRef.current = {
                notificationdescription: document.data().description,
                invitee: "",
                proposalid: document.data().id,
                displayname: userName,
            };
        }

        setInviteDropdownVisible(!inviteDropdownVisible);
    };

    const handleFriendClick = (friendData, userName) => {
        inviteDataRef.current.invitee = friendData.id;
        const inviteData = inviteDataRef.current;
        try {
            const result = inviteFriend(inviteData);
        } catch (error) {
            console.log("Error Inviting Friend");
        }
    };

    const handleStartVoting = () => {
        if (document) {
            const proposalData = document.data();
            startVoting(proposalData, (result) => {
                if (result && result.documentIds) {
                    console.log("Document IDs:", result.documentIds);
                }
            });
        }
    };

    const openGoogleMaps = () => {
        if (restaurantData && restaurantData.location) {
            const { address1, city, zip_code } = restaurantData.location;
            const query = `${address1}, ${city}, ${zip_code}`;
            const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(query)}`;
            window.open(url, '_blank');
        }
    };

    const handleShare = () => {
        if (document && document.data()) {
            const proposalId = document.data().id;
            if (!proposalId) {
                console.error("Proposal ID is missing.");
                return;
            }

            if (!document.data().public) {
                if (window.confirm("Confirm public share. People you may not know will be allowed to join your event based on how you share.")) {
                    setProposalPublic({ id: proposalId, public: true })
                        .then((result) => {
                            createShareableLink();
                        })
                        .catch((error) => {
                            console.error("Error making proposal public:", error);
                            alert("There was an issue making the proposal public. Please try again.");
                        });
                }
            } else {
                createShareableLink();
            }
        }
    };

    const createShareableLink = () => {
        const link = `https://didema.twentyies.com/#/proposal/${document.data().id}`;
        const shareText = `Help ${userName} decide where to eat!\n${link}`;

        if (navigator.share) {
            navigator.share({
                title: "DiDeMa",
                text: shareText,
                url: link,
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        } else {
            navigator.clipboard.writeText(shareText)
                .then(() => {
                    alert("Shareable link copied to clipboard!");
                })
                .catch(console.error);
        }
    };

    const handleCloseTutorial = () => {
        setShowTutorial(false);
    };

    return (
        <>
            {!document ? (
                <div>
                    {/* Placeholder content while document is loading */}
                    <p>Loading...</p>
                </div>
            ) : (
                <div className="ContentDisplay" style={styles}>
                    {visibleItem && (
                        <div style={{ height: '50vh', width: '50vw' }}>
                            <GoogleMap proposal={document.data()} restaurant={restaurantData} />
                        </div>
                    )}

                    {/* Tutorial bubble */}
                    {showTutorial && (
                        <div className="tutorial-bubble" style={{ 
                            backgroundColor: 'black', 
                            color: 'white', 
                            padding: '15px', 
                            borderRadius: '5px', 
                            marginTop: '20px', 
                            position: 'relative' 
                        }}>
                            <span 
                                onClick={handleCloseTutorial} 
                                style={{ 
                                    position: 'absolute', 
                                    top: '5px', 
                                    right: '10px', 
                                    cursor: 'pointer', 
                                    fontSize: '18px', 
                                    fontWeight: 'bold' 
                                }}>
                                X
                            </span>
                            <p>
                                Press "Start Voting" to pull in restaurants and sort them accordingly. Once done, hit "Done Sorting" to finalize your results.
                                <br />
                                <strong>Note:</strong> Adding Friends and sharing the proposal is only available through the IOS app at this time. Full funcionality will be added later. 
                            </p>
                        </div>
                    )}

                    <button onClick={() => setVisibleItem(!visibleItem)}>
                        Show Google Map
                    </button>

                    <div>
                        <button onClick={handleStartVoting}>Start Voting</button>
                        <button onClick={handleInvite} style={{ marginTop: "10px", width: "40px" }}>
                            Invite
                        </button>
                        {inviteDropdownVisible && (
                            <FriendList onFriendClick={handleFriendClick} />
                        )}
                        <button onClick={handleShare}>Share Proposal</button>
                        <button onClick={openGoogleMaps}>Get Directions</button>
                        <VotingSort proposal={document.data()} />
                    </div>
                </div>
            )}
        </>
    );
}

export default ContentArea;
