import React, { useEffect, useState } from "react";
import { auth, db } from '../../Firebase/Firebase';
import { doc, getDoc } from "firebase/firestore";
import FriendList from "../../components/FriendList/FriendList";
import './CogFriendsList.css';

const CogFriendsList = () => {
    const [friendsList, setFriendsList] = useState([]);
    const [userName, setUserName] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDocument = async () => {
            const user = auth.currentUser;

            if (user) {
                try {
                    const docRef = doc(db, "users", user.uid);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        const userData = docSnap.data();
                        setFriendsList(userData.friends || []);
                        setUserName(userData.name || '');
                    } else {
                        console.log("No such document!");
                        setFriendsList([]);
                    }
                } catch (error) {
                    console.error("Error fetching document:", error);
                }
            } else {
                console.log("No user is currently authenticated.");
                setFriendsList([]);
            }

            setLoading(false);
        };

        fetchDocument();
    }, []);

    const handleFriendClick = (friendData) => {
        console.log("Friend clicked:", friendData);
        // Implement what happens when a friend is clicked
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="CogFriendsList">
            <h2>Friends List</h2>
            <div className="FriendsList">
                <FriendList
                    onFriendClick={handleFriendClick} // Ensure this is passed correctly
                />
            </div>
        </div>
    );
};

export default CogFriendsList;
