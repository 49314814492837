import React, { useEffect, useState, useRef } from "react";
import GoogleMapReact from "google-map-react";
import './NewProposalGoogleMap.css';

export default function NewProposalGoogleMap(props) {
  const [circleCenter, setCircleCenter] = useState({ lat: props.latitude || 35, lng: props.longitude || 125 });
  const [mapLoaded, setMapLoaded] = useState(false);
  const [cityName, setCityName] = useState("");
  const GOOGLE_API_KEY = "AIzaSyDbKWSX-WO1oQyFbmlc9f5Z5YlxMgEtVCo"; // Replace with your actual API key
  const mapRef = useRef(null);
  const circleRef = useRef(null);
  const geocoderRef = useRef(null);

  const mapOptions = {
    draggable: true,
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    fullscreenControl: false,
  };

  useEffect(() => {
    if (mapLoaded && circleRef.current) {
      circleRef.current.setRadius(props.circleSize || 1000);
      mapRef.current.map.setCenter(circleRef.current.getCenter());
    }
  }, [props.circleSize, mapLoaded]);

  useEffect(() => {
    if (mapLoaded) {
      reverseGeocode(circleCenter);
    }
  }, [circleCenter, mapLoaded]);

  useEffect(() => {
    if (cityName) {
      props.onCityNameChange(cityName);
    }
  }, [cityName]);

  useEffect(() => {
    if (mapLoaded) {
      const newCenter = { lat: props.latitude || 35, lng: props.longitude || 125 };
      circleRef.current.setCenter(newCenter);
      setCircleCenter(newCenter);
      mapRef.current.map.setCenter(newCenter);
    }
  }, [props.latitude, props.longitude, mapLoaded]);

  const handleApiLoaded = ({ map, maps }) => {
    mapRef.current = { map, api: maps };
    setMapLoaded(true);

    geocoderRef.current = new maps.Geocoder();

    const center = { lat: props.latitude || 35, lng: props.longitude || 125 };

    circleRef.current = new maps.Circle({
      strokeColor: "DodgerBlue",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "DodgerBlue",
      fillOpacity: 0.35,
      map,
      center,
      radius: props.circleSize || 1000,
    });

    map.addListener('dblclick', (e) => {
      const clickedLocation = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      circleRef.current.setCenter(clickedLocation);
      setCircleCenter(clickedLocation);
      map.setCenter(clickedLocation);

      if (props.onLocationChange) {
        props.onLocationChange(clickedLocation);
      }
    });
  };

  const geocodeAddress = (address) => {
    if (geocoderRef.current) {
      geocoderRef.current.geocode({ 'address': address }, (results, status) => {
        if (status === 'OK') {
          const location = results[0].geometry.location;
          const newCenter = { lat: location.lat(), lng: location.lng() };
          circleRef.current.setCenter(newCenter);
          setCircleCenter(newCenter);
          mapRef.current.map.setCenter(newCenter);
        } else {
          console.error('Geocode error:', status);
        }
      });
    }
  };

  const reverseGeocode = (location) => {
    if (geocoderRef.current) {
      geocoderRef.current.geocode({ 'location': location }, (results, status) => {
        if (status === 'OK') {
          const addressComponents = results[0].address_components;
          const cityComponent = addressComponents.find(component => component.types.includes("locality"));
          const townComponent = addressComponents.find(component => component.types.includes("sublocality") || component.types.includes("administrative_area_level_2"));
          const city = cityComponent ? cityComponent.long_name : '';
          const town = townComponent ? townComponent.long_name : '';
          setCityName(city || town || "Unknown location");
        } else {
          console.error('Reverse geocode error:', status);
          setCityName("Unknown location");
        }
      });
    }
  };

  return (
    <div>
      <div>
        <input type="text" id="address" placeholder="Enter address" />
        <button onClick={() => geocodeAddress(document.getElementById('address').value)}>Geocode</button>
      </div>
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
          defaultCenter={{ lat: props.latitude || 35, lng: props.longitude || 125 }}
          center={circleCenter}
          zoom={14 - Math.sqrt(props.zoom || 1)}
          options={mapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={handleApiLoaded}
        />
      </div>
      <div>
        <p className="city-name">Current City/Town: {cityName}</p>
      </div>
    </div>
  );
}
