import React, { useState, useEffect } from 'react';
import './Profile.css';
import { storage, auth, db } from '../../Firebase/Firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const Profile = () => {
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [downloadURL, setDownloadURL] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [nameErrorMessage, setNameErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showTutorial, setShowTutorial] = useState(false);
    const [showSecondTutorial, setShowSecondTutorial] = useState(false); // New state for the second tutorial bubble

    const fetchUserProfile = async () => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            try {
                const userDocRef = doc(db, 'users', currentUser.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setDisplayName(userData.displayname || '');
                    setDownloadURL(userData.avatarurl || '');

                    if (userData.newuserexperience === "false") {
                        setShowTutorial(true);
                    }

                    // Check if profile picture and display name exist
                    if (userData.avatarurl && userData.displayname) {
                        setShowSecondTutorial(true); // Show second tutorial if both are present
                    }
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        }
    };

    useEffect(() => {
        fetchUserProfile();
    }, []);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile.type !== 'image/png') {
                setErrorMessage('Please upload an image in PNG format.');
                setFile(null);
                return;
            }
            if (selectedFile.size >= 100 * 1024) {
                setErrorMessage('Please upload an image under 100KB.');
                setFile(null);
                return;
            }
            setFile(selectedFile);
            setErrorMessage('');
        }
    };

    const handleUpload = () => {
        if (!file) return;
        const currentUser = auth.currentUser;
        if (!currentUser) {
            console.log("No user is logged in.");
            return;
        }

        const storageRef = ref(storage, `users/${currentUser.uid}/profilePicture.png`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        setUploading(true);

        uploadTask.on(
            'state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(`Upload is ${progress}% done`);
            },
            (error) => {
                console.error('Upload failed', error);
                setUploading(false);
                setErrorMessage('Upload failed. Please try again.');
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    console.log('File available at', downloadURL);
                    setDownloadURL(downloadURL);
                    setUploading(false);
                    setErrorMessage('');
                    setSuccessMessage('Profile picture updated successfully!');
                    setTimeout(() => {
                        setSuccessMessage('');
                    }, 3000);
                    const userDocRef = doc(db, 'userspublic', currentUser.uid);
                    await setDoc(userDocRef, { avatarurl: downloadURL }, { merge: true });

                    // Show second tutorial if both profile picture and display name are present
                    if (downloadURL && displayName) {
                        setShowSecondTutorial(true);
                    }
                });
            }
        );
    };

    const handleNameChange = (e) => {
        setDisplayName(e.target.value);
    };

    const handleSaveDisplayName = async () => {
        const currentUser = auth.currentUser;
        if (!currentUser) {
            console.log("No user is logged in.");
            return;
        }

        if (displayName.trim() === '') {
            setNameErrorMessage('Display name cannot be empty.');
            return;
        }

        try {
            const userDocRef = doc(db, 'userspublic', currentUser.uid);
            await setDoc(userDocRef, { displayname: displayName }, { merge: true });
            setNameErrorMessage('');
            setSuccessMessage('Display name updated successfully!');
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);

            // Show second tutorial if both profile picture and display name are present
            if (downloadURL && displayName) {
                setShowSecondTutorial(true);
            }
        } catch (error) {
            console.error('Error updating display name:', error);
            setNameErrorMessage('Failed to update display name. Please try again.');
        }
    };

    const handleCloseTutorial = () => {
        setShowTutorial(false);
    };

    return (
        <div className="profile" id="profileWindow">
            {showTutorial && (
                <div className="tutorial-bubble" style={{ 
                    backgroundColor: 'black', 
                    color: 'white', 
                    padding: '10px', 
                    borderRadius: '5px', 
                    position: 'absolute', 
                    top: '20px', 
                    right: '20px', 
                    border: '2px solid #fa323f' 
                }}>
                    <span style={{ 
                        position: 'absolute', 
                        top: '5px', 
                        right: '10px', 
                        cursor: 'pointer' 
                    }} onClick={handleCloseTutorial}>X</span>
                    <p>Welcome to DiDema! To start, please select a profile picture and a display name.</p>
                </div>
            )}

            {showSecondTutorial && (
                <div className="tutorial-bubble" style={{ 
                    backgroundColor: 'black', 
                    color: 'white', 
                    padding: '10px', 
                    borderRadius: '5px', 
                    position: 'absolute', 
                    top: '80px',  // Positioned below the first bubble
                    right: '20px', 
                    border: '2px solid #fa323f' 
                }}>
                    <p>Perfect, now you can return home and create a new proposal.</p>
                </div>
            )}

            <h2>Edit Profile</h2>
            {downloadURL && (
                <div>
                    <img type="imageAvatar" src={downloadURL} alt="Uploaded Avatar" />
                </div>
            )}
            <p><input type="file" onChange={handleFileChange} /></p>
            <button onClick={handleUpload} disabled={uploading || !file}>
                {uploading ? 'Uploading...' : 'Upload'}
            </button>
            {errorMessage && (
                <div className="error-popup" style={{ color: 'red' }}>
                    {errorMessage}
                </div>
            )}

            <div className="displayNameSection">
                <input
                    type="inputDisplayName"
                    id="inputDisplayName"
                    value={displayName}
                    onChange={handleNameChange}
                    placeholder="Enter new display name"
                    maxLength={100}
                />
                <button onClick={handleSaveDisplayName}>Save Display Name</button>
                {nameErrorMessage && (
                    <div className="error-popup" style={{ color: 'red' }}>
                        {nameErrorMessage}
                    </div>
                )}
            </div>

            {successMessage && (
                <div className="success-popup" style={{ color: 'green' }}>
                    {successMessage}
                </div>
            )}
        </div>
    );
};

export default Profile;
