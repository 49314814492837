import React, { useState, useEffect } from 'react';
import { auth, db } from '../../Firebase/Firebase';
import { useNavigate } from 'react-router-dom';
import { deleteAccount } from '../../Firebase/Firebase'; // Import the helper function
import { doc, getDoc } from 'firebase/firestore';

const AccountSettings = () => {
    const [blockedList, setBlockedList] = useState(null);
    const [blockedMessage, setBlockedMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Cleanup on component unmount
        return () => {
            setBlockedList(null);
            setBlockedMessage('');
        };
    }, []);

    const handleShowBlocked = async () => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            const userDocRef = doc(db, 'userspublic', currentUser.uid); // Update to use `doc`
            const userDoc = await getDoc(userDocRef); // Update to use `getDoc`
            if (userDoc.exists()) {
                const userData = userDoc.data();
                if (userData.blocked && userData.blocked.length > 0) {
                    setBlockedList(userData.blocked);
                } else {
                    setBlockedMessage('Blocked list empty.');
                }
            } else {
                setBlockedMessage('User data not found.');
            }
        }
    };

    const handleLogout = () => {
        auth.signOut().then(() => {
            console.log('User logged out.');
            navigate("/");
        }).catch((error) => {
            console.error('Logout error:', error);
        });
    };

    const handleDeleteAccount = () => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            deleteAccount(currentUser.uid, (result, error) => {
                if (error) {
                    console.error('Error deleting account:', error);
                } else {
                    console.log('Account deleted successfully:', result);
                    navigate("/"); // Navigate to home after deletion
                }
            });
        }
    };

    return (
        <div style={{ backgroundColor: 'inherit', color: 'white', padding: '20px', borderRadius: '8px' }}>
            <h2>Account Settings</h2>
            <button onClick={handleShowBlocked}>Show Blocked</button>
            {blockedList ? (
                <ul>
                    {blockedList.map((blockedUser, index) => (
                        <li key={index}>{blockedUser}</li>
                    ))}
                </ul>
            ) : (
                <p>{blockedMessage}</p>
            )}
            <button onClick={handleLogout}>Logout</button>
            <button onClick={handleDeleteAccount}>Delete Account</button>
        </div>
    );
};

export default AccountSettings;
