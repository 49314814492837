
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc} from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import axios from "axios";
import { getFunctions, httpsCallable } from "firebase/functions";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDbKWSX-WO1oQyFbmlc9f5Z5YlxMgEtVCo",
  authDomain: "didema-d76c4.firebaseapp.com",
  projectId: "didema-d76c4",
  storageBucket: "didema-d76c4.appspot.com",
  messagingSenderId: "421178807611",
  appId: "1:421178807611:web:fe5184c329c70867f2931e",
  measurementId: "G-7N3M4GMSHK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const functions = getFunctions(app);
export const storage = getStorage(app);


//const docRef = doc(db,"users", {authUser}  );
//const docSnap = await getDoc(docRef);
//const analytics = getAnalytics(app);
export const auth = getAuth(app);
const baseURLString = "https://us-central1-didema-d76c4.cloudfunctions.net";
const businessGetUrlString = "/businessget/"
const didemaApi = axios.create({
  baseURL:baseURLString,
});

// Create a new user in the database
export const createNewUser = (name) => {
  const createnewuserFunc = httpsCallable(functions, 'createnewuser');
  
  return createnewuserFunc({ name })
    .then((response) => {
      console.log('New user created successfully:', response.data);
      return response.data;
    })
    .catch((error) => {
      console.error('Error creating new user:', error.message);
      throw error;
    });
};

export const finishTutorial = (falseFlag) => {
  const finishTutorialFunc = httpsCallable(functions, 'finishtutorial');

  return finishTutorialFunc(falseFlag)
    .then((response) => {
      console.log('Tutorial finished successfully:', response.data);
      return response.data;
    })
    .catch((error) => {
      console.error('Error finishing tutorial:', error.message);
      throw error;
    });
};


export const createCustomMeal = (mealName, mealData, completion) => {
  const createCustomMealFunc = httpsCallable(functions, 'createCustomMeal');
  
  createCustomMealFunc({ mealName, data: mealData })
    .then((response) => {
      console.log('Custom meal created successfully:', response.data);
      completion(response.data); // Call the completion callback with the response data
    })
    .catch((error) => {
      console.error('Error creating custom meal:', error);
      completion(null, error); // Call the completion callback with the error
    });
};


export const deleteAccount = (userId, completion) => {
  const deleteAccountFunc = httpsCallable(functions, 'deleteAccount');
  
  deleteAccountFunc({ userId })
    .then((response) => {
      console.log('Account deleted successfully:', response.data);
      completion(response.data); // Call the completion callback with the response data
    })
    .catch((error) => {
      console.error('Error deleting account:', error);
      completion(null, error); // Call the completion callback with the error
    });
};

export const getRestaurant = async (restaurantId, completion) => {
  try {
    console.log("Axios urlstring ", businessGetUrlString);
    console.log("Axios restaurantid", restaurantId);
    console.log("Current User Id Token", auth.currentUser.accessToken  );
    const response = await didemaApi.get(businessGetUrlString + restaurantId, {
      headers: {
        Authorization: `Bearer ${auth.currentUser.accessToken}`,
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Request-Method": "GET",
      },
    });

    if (response.status === 200) {
      completion(response.data);
    } else {
      console.log("Non 200 status ", response.statusText);
      completion(response.statusText);
    }
  } catch(error) {
    console.log("Axios error ", error);
    completion(error)
  }
};

export const createProposal = (proposalData) => {
  const addProposal = httpsCallable(functions, 'createproposal');
  return addProposal({ proposalData }) // Notice the format { proposalData }
    .then((response) => {
      const data = response.data;
      console.log('Proposal created successfully:', data);
      return data; // Return the data so it can be used by the caller
    })
    .catch((error) => {
      console.error('Error creating proposal:', error);
      throw error; // Re-throw the error so it can be caught by the caller
    });
};

export const inviteFriend = (inviteData) => {

  const invite = httpsCallable(functions, 'invite');

  invite(inviteData)
    .then((response) => {

      const data = response.data;
      console.log ('User invited :', data);
    })
    .catch((error) => {
      console.error('Error inviting', error);


    });

/*
data format:  {"notificationdescription":"Dinner, Today near San Ramon","invitee":"VNesPc8rBcfBnuLveHoh41aJAno1","proposalid":"DD60D363-E61B-4752-BF64-DAF600BBD884","displayname":"Alex"}"
*/
};




export const setProposalState = async (proposalData) => {
  const setProposalStateFunction = httpsCallable(functions, 'setproposalstate');
  try {
    const response = await setProposalStateFunction(proposalData);
    const data = response.data;
    console.log('Proposal modified successfully:', data);
    return response.statusText; // Return the status text
  } catch (error) {
    console.error('Error modifying proposal:', error);
    throw error; // Re-throw the error to be caught by the caller
  }
};


export const setProposalSorted = async (proposalData) => {
  const setProposalSortedFunction = httpsCallable(functions, 'setproposalsorted');
  try {
    const response = await setProposalSortedFunction(proposalData);
    const data = response.data;
    console.log('Proposal modified successfully:', data);
    return response.statusText; // Return the status text
  } catch (error) {
    console.error('Error modifying proposal:', error);
    throw error; // Re-throw the error to be caught by the caller
  }
};






export const setProposalRestaurantPool = (proposalData, completion) => {
  // Reference to the Cloud Function
  const setProposalRestaurantPool = httpsCallable(functions, 'setproposalrestaurantpool');

  // Call the Cloud Function with the provided data
  setProposalRestaurantPool({ proposalData }) // Notice the format { proposalData }
    .then((response) => {
      // response is the return value of the function call
      const data = response.data;
      // Handle the returned data from the Cloud Function
      console.log('Proposal pool modified successfully:', data);
      completion(response.statusText);
    })
    .catch((error) => {
      // Handle any errors that occurred during the Cloud Function call
      console.error('Error pool modifying proposal: ', error);
      completion(error);
    });
};

export const makeDecision = async (proposalId) => {
  const makeDecisionFunction = httpsCallable(functions, 'makeDecision');
  try {
    const response = await makeDecisionFunction(proposalId);
    const result = response.data.decision;
    console.log('Decision made successfully:', result);
    return result;
  } catch (error) {
    console.error('Error making decision:', error);
    throw error;
  }
};



export const setProposalPublic = (proposalData) => {
  // Reference to the Cloud Function
  const setProposalPublicFunction = httpsCallable(functions, 'setproposalpublic');

  // Call the Cloud Function with the provided data
  return setProposalPublicFunction(proposalData) // Directly pass proposalData
    .then((response) => {
      // response is the return value of the function call
      const data = response.data;
      console.log('Proposal public successfully:', data);
      return data;
    })
    .catch((error) => {
      console.error('Error setting public proposal: ', error);
      throw error;
    });
};


export const setProposalMeal = (proposalData, completion) => {
  // Reference to the Cloud Function
  const setProposalMeal = httpsCallable(functions, 'setproposalmeal');

  // Call the Cloud Function with the provided data
  setProposalMeal({ proposalData }) // Notice the format { proposalData }
    .then((response) => {
      // response is the return value of the function call
      const data = response.data;
      // Handle the returned data from the Cloud Function
      console.log('Proposal meal set successfully:', data);
      completion(response.statusText);
    })
    .catch((error) => {
      // Handle any errors that occurred during the Cloud Function call
      console.error('Error setting meal proposal: ', error);
      completion(error);
    });
};
export const removeProposalMember = (proposalId, userId, completion = () => {}) => {
  const removeProposalMember = httpsCallable(functions, 'removeproposalmember');

  removeProposalMember({ userid: userId, proposalid: proposalId })
      .then((response) => {
          const data = response.data;
          console.log('Proposal removed member successfully: ', data);
          completion(response.statusText);
      })
      .catch((error) => {
          console.error('Error removing member: ', error);
          completion(error);
      });
};


export const startVoting = (proposalData, completion) => {
  const startVotingFunction = httpsCallable(functions, 'startVoting');

  startVotingFunction({ proposal: proposalData })
    .then((response) => {
      const data = response.data;
      console.log('Voting started successfully:', JSON.stringify(data));

      if (data && data.documentIds) {
        completion(data.documentIds);
      } else {
        completion('No document IDs returned.');
      }
    })
    .catch((error) => {
      // Handle errors and ensure 'error' is defined
      console.error('Error starting voting:', error);
      if (error && error.message) {
        completion(error.message);
      } else {
        completion('An unknown error occurred.');
      }
    });
    
};



