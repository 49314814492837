
import React, {useEffect} from "react";
import ProposalList from "../../components/ProposalList/ProposalList";
import { auth } from '../../Firebase/Firebase';
import './ProposalRender.css';
import { useParams } from "react-router-dom";
import ContentArea from "../ContentArea/ContentArea";

function ProposalRender(){
    
    const routeParams = useParams();
    const [authUser, setAuthUser] = React.useState(null);
  
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((retAuthUser) => {
          setAuthUser(retAuthUser);
      });
      
      return () => unsubscribe();
    },[authUser]);

    return<>
    <div className = 'ProposalRenderRow'>
       <ProposalList proposalId = {routeParams.id}/>
       {console.log("PROPOSAL RENDER Current data", routeParams.id)}
       <ContentArea proposalId = {routeParams.id}/>
    </div>

    </>
}
export default ProposalRender
//<ul className="sidebar__bg">
//{sideNavBar}
//</ul>