import React, { useEffect, useState } from 'react';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth, db, createNewUser } from '../../Firebase/Firebase';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';

function UserHeader() {
    const [authUser, setAuthUser] = useState(null);
    const [redirected, setRedirected] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (retAuthUser) => {
            setAuthUser(retAuthUser);
            console.log("UserHeader.useEffect auth state changed:", retAuthUser);

            if (retAuthUser) {
                const currentPath = window.location.pathname;
                console.log("Current path:", currentPath);

                console.log("Checking user document for:", retAuthUser.uid);
                const userDocRef = doc(db, 'users', retAuthUser.uid);
                const userDoc = await getDoc(userDocRef);

                if (!userDoc.exists()) {
                    console.log("Creating new user for:", retAuthUser.uid);
                    await createNewUser(retAuthUser.displayName);
                }

                console.log("Checking user public document for:", retAuthUser.uid);
                const userPublicDocRef = doc(db, 'userspublic', retAuthUser.uid);
                const userPublicDoc = await getDoc(userPublicDocRef);

                if (!userPublicDoc.exists() || !userPublicDoc.data().avatarurl) {
                    if (currentPath !== "/profile" && !redirected) {
                        console.log("Redirecting to profile page.");
                        setRedirected(true);
                        navigate("/profile", { replace: true });
                    }
                } else {
                    if (currentPath !== "/proposals" && !redirected) {
                        console.log("Redirecting to proposals page.");
                        setRedirected(true);
                        navigate("/proposals", { replace: true });
                    }
                }
            } else {
                const currentPath = window.location.pathname;
                if (currentPath !== "/" && !redirected) {
                    console.log("Redirecting to home page.");
                    setRedirected(true);
                    navigate("/");
                }
            }
        });

        return () => unsubscribe();
    }, [authUser, navigate, redirected]);

    const loginWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                setAuthUser(result.user);
                console.log('Logged in user', result.user);
            })
            .catch((error) => {
                console.log('Login error:', error.message);
            });
    };

    return (
        <div style={{ color: 'white' }}> {/* Set text color to white */}
            {!authUser ? (
                <div>
                    <button type="button" onClick={loginWithGoogle}>Login with Google To Get Started</button>
                </div>
            ) : (
                <div>
                    {/* Only render the login button if authUser is null */}
                </div>
            )}
        </div>
    );
}

export default UserHeader;
