import React, { useEffect, useState } from 'react';
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import Friend from './Friend';
import { db, auth } from '../../Firebase/Firebase';
import './FriendList.css';

function FriendList({ onFriendClick = () => {} }) { // Default to empty function
    const userId = auth.currentUser?.uid;
    const [document, setDocument] = useState(null);
    const [friendList, setFriendList] = useState([]);

    useEffect(() => {
        if (!userId) return;

        const unsub = onSnapshot(doc(db, "users", userId), (doc) => {
            setDocument(doc);
        });
        return () => unsub();
    }, [userId]);

    useEffect(() => {
        if (document) {
            const friends = document.data().friends || []; // Default to empty array
            const friendRefs = friends.map(id => doc(db, "userspublic", id));
            Promise.all(friendRefs.map(ref => getDoc(ref)))
                .then(friendsSnapshots => {
                    const friendsData = friendsSnapshots.map(snapshot => snapshot.data()).filter(data => data); // Filter out any undefined data
                    setFriendList(friendsData);
                })
                .catch(error => {
                    console.log("Error getting friend documents:", error);
                });
        }
    }, [document]);

    return (
        <>
            {!document ? (
                <div>
                    Loading...
                </div>
            ) : (
                <div className='friend__list-container'>
                    <p>Friends:</p>
                    <ul>
                        {friendList.length > 0 ? (
                            friendList.map((friendData) => (
                                <Friend 
                                    key={friendData.id} 
                                    data={friendData} 
                                    onClick={() => onFriendClick(friendData)} 
                                />
                            ))
                        ) : (
                            <li>No friends available</li>
                        )}
                    </ul>
                </div>
            )}
        </>
    );
}

export default FriendList;
