import './App.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { BUILD_VERSION, BUILD_TIMESTAMP } from './Version';

import Home from './containers/Home/Home';
import LoggedIn from './containers/LoggedIn/LoggedIn';
import ProposalRender from './containers/ProposalRender/ProposalRender';
import NewProposal from './containers/NewProposal/NewProposal';
import UserHeader from './components/UserHeader/UserHeader';
import Navbar from './components/Navbar/Navbar';
import FriendList from './components/FriendList/FriendList';
import Profile from './components/Profile/Profile';
import AccountSettings from './components/AccountSettings/AccountSettings';
import CogFriendsList from './components/CogFriendsList/CogFriendsList';
import Restaurants from './components/Restaurants/Restaurants';

function App() {

  const userHeader = <UserHeader />;
  const navbar = <Navbar />;

  return (
    <>
      <nav>
        <ul className="gradient__bg">
          {navbar}
          {userHeader}
        </ul>
      </nav>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/proposals" exact element={<LoggedIn />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<AccountSettings />} />
        <Route path="/Friends" element={<CogFriendsList />} />
        <Route path="/Restaurants" element={<Restaurants />} />
        <Route path="friendlist" element={<><FriendList /><LoggedIn /></>} />
        <Route path="/proposals/:id" element={<><ProposalRender /></>} />
        <Route path="/newproposal" element={<NewProposal />} />
      </Routes>


    </>
  );
}

export default App;
