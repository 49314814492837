import React, { useEffect, useState } from "react";
import ProposalList from "../../components/ProposalList/ProposalList";
import SideNavbar from "../../components/SideNavbar/SideNavbar";
import { auth, db } from '../../Firebase/Firebase'; // Import Firestore db
import { doc, getDoc } from "firebase/firestore"; // Import Firestore functions
import './LoggedIn.css';
import FriendList from "../../components/FriendList/FriendList";
import GoogleMap from "../../components/GoogleMap/GoogleMap";
import { Route, Routes, Link, useRouteMatch } from 'react-router-dom';

function LoggedIn() {
    const [authUser, setAuthUser] = useState(null);
    const [showTutorial, setShowTutorial] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((retAuthUser) => {
            setAuthUser(retAuthUser);
            if (retAuthUser) {
                checkNewUserExperience(retAuthUser.uid); // Check user experience on login
            }
        });

        return () => unsubscribe();
    }, [authUser]);

    // Function to check if new user experience is set to "false"
    const checkNewUserExperience = async (userId) => {
        try {
            const userDocRef = doc(db, 'users', userId);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                const userData = userDoc.data();
                if (userData.newuserexperience === "false") {
                    setShowTutorial(true);
                }
            } else {
                console.log('No such document!');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const handleCloseTutorial = () => {
        setShowTutorial(false);
    };

    return (
        <>
            {showTutorial && (
                <div className="tutorial-bubble" style={{ 
                    backgroundColor: 'black', 
                    color: 'white', 
                    padding: '10px', 
                    borderRadius: '5px', 
                    position: 'absolute', 
                    top: '20px', 
                    right: '20px', 
                    border: '2px solid #fa323f' 
                }}>
                    <span style={{ 
                        position: 'absolute', 
                        top: '5px', 
                        right: '10px', 
                        cursor: 'pointer' 
                    }} onClick={handleCloseTutorial}>X</span>
                    <p>Click on Create New Proposal to get started.</p>
                </div>
            )}

            <div>
                <ProposalList />
            </div>
        </>
    );
}

export default LoggedIn;
