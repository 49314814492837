import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NewProposalGoogleMap from "../../components/NewProposalGoogleMap/NewProposalGoogleMap";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import "./NewProposal.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker/dist/TimePicker.css";
import { createProposal, db ,storage, auth, } from "../../Firebase/Firebase"; // Import Firestore db
import { doc, getDoc } from "firebase/firestore"; // Import Firestore functions

export default function NewProposal() {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [circleRadiusMiles, setCircleRadiusMiles] = useState(1);
  const [eventDate, setEventDate] = useState(new Date());
  const [eventTime, setEventTime] = useState("12:00");
  const [cost, setCost] = useState("1");
  const [meal, setMeal] = useState("Breakfast");
  const [cityName, setCityName] = useState("");
  const [isCreated, setIsCreated] = useState(false);
  const [showNewOption, setShowNewOption] = useState(false);
  const [existingNewOption, setExistingNewOption] = useState("Existing");
  const [customMeals, setCustomMeals] = useState([]);
  const [showCreateButton, setShowCreateButton] = useState(false);
  const [loading, setLoading] = useState(false); // Added loading state
  const [showTutorial, setShowTutorial] = useState(false); // For tutorial bubble
  const navigate = useNavigate();

  const mealMapping = {
    Breakfast: "Breakfast:1:sunrise",
    Lunch: "Lunch:2:sun.max",
    Dinner: "Dinner:3:sunset"
  };
  const costMapping = {
    "$": "1",
    "$$": "2",
    "$$$": "3",
    "$$$$": "4"
  };
  

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setMapLoaded(true);
        },
        (error) => {
          console.error("Error finding location:", error);
          setMapLoaded(true);
        }
      );
    } else {
      setMapLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (latitude && longitude) {
      // Fetch custom meals if needed
      // Example: setCustomMeals(fetchCustomMealsFromAPI());
    }
  }, [latitude, longitude]);

  useEffect(() => {
    // Check if the user has gone through the new user experience
    const fetchUserExperience = async () => {
      const userId = auth.currentUser.uid;
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.newuserexperience === "false") {
          setShowTutorial(true);
        }
      }
    };
    
    if (auth.currentUser) {
      fetchUserExperience();
    }
  }, []);

  const handleProposalCreation = async () => {
    if (!latitude || !longitude || isCreated) return;

    setLoading(true); // Set loading to true when starting proposal creation

    const proposalData = {
      cost,
      createdtime: new Date().toISOString(),
      description: `${meal}, near ${cityName}`,
      details: cityName,
      eventtime: eventDate.toISOString(),
      id: "", // This will be updated by createProposal
      joined: [""],
      location: {
        latitude,
        longitude
      },
      meal: mealMapping[meal],
      owner: "",
      range: circleRadiusMiles * 1609,
      votes: {}
    };

    try {
      // Await the result of createProposal, which should include the proposal ID
      const newProposalId = await createProposal(proposalData);
      
      if (newProposalId) {
        setIsCreated(true);
        navigate(`/proposals/${newProposalId.id}`); // Navigate to the new proposal page
      } else {
        console.error("Failed to create proposal.");
      }
    } catch (error) {
      console.error("Error creating proposal:", error);
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  };

  const handleCostChange = (event) => {
    setCost(costMapping[event.target.value]);
  };

  const handleMealChange = (event) => {
    setMeal(event.target.value);
    setShowNewOption(event.target.value === "Custom");
  };

  const handleNewOptionChange = (event) => {
    setExistingNewOption(event.target.value);
  };

  const handleCreateCustomMeal = () => {
    // Handle the creation of a new custom meal
    setShowCreateButton(true);
  };

  const handleCloseTutorial = () => {
    setShowTutorial(false);
  };

  return (
    <div className="NewProposal">
      {loading ? (
        <div className="loader"></div>
      ) : !mapLoaded ? (
        <div className="loader">
          <h2>Finding your location...</h2>
        </div>
      ) : (
        <div className="new-proposal-container">
          {showTutorial && (
            <div className="tutorial-bubble" style={{ 
              backgroundColor: 'black', 
              color: 'white', 
              padding: '10px', 
              borderRadius: '5px', 
              position: 'absolute', 
              top: '20px', 
              right: '20px', 
              border: '2px solid #fa323f' 
            }}>
              <span style={{ 
                position: 'absolute', 
                top: '5px', 
                right: '10px', 
                cursor: 'pointer' 
              }} onClick={handleCloseTutorial}>X</span>
              <p>Here you can set the parameters for your Restaurant Search</p>
            </div>
          )}

          {latitude && longitude ? (
            <NewProposalGoogleMap
              latitude={latitude}
              longitude={longitude}
              circleSize={circleRadiusMiles * 1609} // Pass circle size in meters
              onCityNameChange={setCityName}
            />
          ) : (
            <p>Unable to get location. Please try again.</p>
          )}

          {latitude && longitude && (
            <>
              <DatePicker
                selected={eventDate}
                onChange={(date) => setEventDate(date)}
              />
              <TimePicker
                onChange={setEventTime}
                value={eventTime}
                className="white-font-timepicker"
              />
              <input
                type="range"
                min="1"
                max="10"
                step="0.5"
                value={circleRadiusMiles}
                onChange={(e) => setCircleRadiusMiles(Number(e.target.value))}
              />
              <span style={{ color: "white", marginLeft: "10px" }}>
                {circleRadiusMiles.toFixed(1)} miles
              </span>
              <div>
                <label htmlFor="cost-selector" className="white-label">Select Cost: </label>
                <select
                  id="cost-selector"
                  value={Object.keys(costMapping).find(key => costMapping[key] === cost)}
                  onChange={handleCostChange}
                >
                  <option value="$">$</option>
                  <option value="$$">$$</option>
                  <option value="$$$">$$$</option>
                  <option value="$$$$">$$$$</option>
                </select>
              </div>

              <div>
                <label htmlFor="meal-selector" className="white-label">Select Meal: </label>
                <select
                  id="meal-selector"
                  value={meal}
                  onChange={handleMealChange}
                >
                  <option value="Breakfast">Breakfast</option>
                  <option value="Lunch">Lunch</option>
                  <option value="Dinner">Dinner</option>
                  <option value="Custom">Custom</option>
                </select>

                {showNewOption && (
                  <div>
                    <label htmlFor="new-option-selector" className="white-label">New Option: </label>
                    <select
                      id="new-option-selector"
                      value={existingNewOption}
                      onChange={handleNewOptionChange}
                    >
                      <option value="Existing">Existing</option>
                      <option value="New">New</option>
                    </select>

                    {existingNewOption === "Existing" && customMeals.length > 0 && (
                      <select>
                        {customMeals.map((mealName, index) => (
                          <option key={index} value={mealName}>
                            {mealName}
                          </option>
                        ))}
                      </select>
                    )}

                    {showCreateButton && (
                      <button onClick={handleCreateCustomMeal}>
                        Create New Custom Meal
                      </button>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
          <button onClick={handleProposalCreation}>Create Proposal</button>
        </div>
      )}
    </div>
  );
}
